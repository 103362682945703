import cn from 'classnames';
import { useTranslation } from 'react-i18next';

const Platform = () => {
  const { t } = useTranslation();
  return (
    <section
      className={cn(
        'flex flex-col bg-[#FFFFFF] justify-center items-center',
        'lg:py-[100px] lg:px-0',
        'py-[50px] px-[20px]',
      )}
    >
      <div className="flex flex-col max-w-[695px]">
        <h6
          className={cn(
            'text-center text-[#2685F4] text-[16px] leading-normal font-semibold',
            'lg:text-[14px] leading-[21px] lg:mb-[4px]',
            'text-[16px] leading-[24px] mb-[2px]',
          )}
        >
          Platform
        </h6>
        <h3
          className={cn(
            'text-[#212529] text-center font-bold leading-[134%] tracking-[-0.3px] mb-[10px]',
            'lg:text-[36px]',
            'text-[24px]',
          )}
        >
          {t('home.platform.title')}
        </h3>
        <p
          className={cn(
            'text-center text-[#808C99] font-normal tracking-[-0.3px] leading-[160%] mb-[30px]',
            'lg:text-[18px]',
            'text-[16px]',
          )}
        >
          {t('home.platform.description')}
        </p>
      </div>
      <div
        className={cn(
          'flex justify-center items-center',
          'lg:mt-[30px] lg:flex-row lg:gap-[50px]',
          'flex-col gap-[30px]',
        )}
      >
        <div className="flex flex-col justify-center items-center">
          <h1
            className={cn(
              'text-[#2685F4] font-bold tracking-[-0.3px]',
              'lg:text-[60px] lg:mb-[10px]',
              'text-[40px] mb-[6px]',
            )}
          >
            {t('home.platform.usersValue')}
          </h1>
          <p
            className={cn(
              'text-[#808C99] text-[16px] tracking-[-0.3px] font-normal',
              'lg:leading-[170%]',
              'leading-[150%]',
            )}
          >
            {t('home.platform.usersLabel')}
          </p>
        </div>
        <div
          className={cn('lg:block lg:h-[80px] lg:w-0 lg:border lg:border-[#F1F2F4]', 'hidden')}
        />
        <div className="flex flex-col justify-center items-center">
          <h1
            className={cn(
              'text-[#2685F4] font-bold tracking-[-0.3px]',
              'lg:text-[60px] lg:mb-[10px]',
              'text-[40px] mb-[6px]',
            )}
          >
            {t('home.platform.appsValue')}
          </h1>
          <p
            className={cn(
              'text-[#808C99] text-[16px] tracking-[-0.3px] font-normal',
              'lg:leading-[170%]',
              'leading-[150%]',
            )}
          >
            {t('home.platform.appsLabel')}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Platform;
