import { Head } from 'components/common';
import { Advertiser, Intro, Partner, Platform, Publisher } from 'components/main';
import Layout from 'layout/index';
import { useTranslation } from 'react-i18next';

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Head description={t('home.intro.description')} />
      <Intro />
      <Platform />
      <Advertiser />
      <Publisher />
      <Partner />
    </Layout>
  );
};

export default IndexPage;
