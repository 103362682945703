import IconGraph from 'assets/main/bar-graph.png';
import IconCheck from 'assets/main/check-circle.png';
import IconEye from 'assets/main/eye.png';
import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Advertiser = () => {
  const { t } = useTranslation();

  const cards = useMemo(
    () => [
      {
        icon: IconEye,
        title: t('home.advertiser.brandAwareness.title'),
        description: t('home.advertiser.brandAwareness.description'),
      },
      {
        icon: IconGraph,
        title: t('home.advertiser.performanceMarketing.title'),
        description: t('home.advertiser.performanceMarketing.description'),
      },
      {
        icon: IconCheck,
        title: t('home.advertiser.rewardedAdvertising.title'),
        description: t('home.advertiser.rewardedAdvertising.description'),
      },
    ],
    [t],
  );

  return (
    <section
      className={cn(
        'flex flex-col bg-[#FBFCFD] justify-center items-center',
        'lg:py-[100px] lg:px-0',
        'py-[50px] px-[20px]',
      )}
    >
      <div className="flex flex-col max-w-[695px]">
        <h6
          className={cn(
            'text-center text-[#2685F4] text-[16px] leading-normal font-semibold',
            'lg:text-[14px] leading-[21px] lg:mb-[4px]',
            'text-[16px] leading-[24px] mb-[2px]',
          )}
        >
          Advertiser
        </h6>
        <h3
          className={cn(
            'text-[#212529] text-center font-bold leading-[134%] tracking-[-0.3px] mb-[10px]',
            'lg:text-[36px]',
            'text-[24px]',
          )}
        >
          {t('home.advertiser.title')}
        </h3>
        <p
          className={cn(
            'text-center text-[#808C99] font-normal tracking-[-0.3px] leading-[160%] mb-[30px]',
            'lg:text-[18px]',
            'text-[16px]',
          )}
        >
          {t('home.advertiser.description')}
        </p>
      </div>
      <div
        className={cn(
          'flex justify-center w-full',
          'lg:mt-[30px] lg:flex-row lg:gap-[40px]',
          'flex-col gap-[20px]',
        )}
      >
        {cards.map(({ icon, title, description }) => (
          <div
            key={title}
            className={cn(
              'flex flex-col items-center bg-[#FFFFFF] rounded-[16px] shadow-[0px_6px_20px_rgba(34,88,159,4%)]',
              'lg:w-[373px] lg:py-[40px] lg:px-[20px]',
              'w-full py-[30px] px-[20px]',
            )}
          >
            <div className="lg:mb-[10px] mb-[6px]">
              <img src={icon} alt="icon.png" width={56} height={56} />
            </div>
            <h5
              className={cn(
                'text-[#212529] font-semibold leading-[150%] tracking-[-0.3px] mb-[4px]',
                'lg:text-[20px]',
                'text-[18px]',
              )}
            >
              {title}
            </h5>
            <p
              className={cn(
                'text-center text-[#808C99] tracking-[-0.3px] font-normal',
                'lg:text-[16px] lg:leading-[160%]',
                'text-[14px] leading-[150%]',
              )}
            >
              {description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Advertiser;
