import IconArrowRight from 'assets/common/ic-narrow-right.svg';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TabKey = 'monetize' | 'adserver' | 'offerwall';

interface PublisherType {
  key: TabKey;
  title: string;
  description: string;
  image: IGatsbyImageData;
}

const Publisher = () => {
  const { t } = useTranslation();
  const [select, setSelect] = useState('monetize');

  const onClickItem = useCallback(
    (tab: TabKey) => (e: MouseEvent) => {
      e.preventDefault();
      setSelect(tab);
    },
    [],
  );

  const { monetize, adserver, offerwall } = useStaticQuery(
    graphql`
      query MainSection3Query {
        monetize: file(absolutePath: { regex: "/main/dynamic-bidfloor.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        adserver: file(absolutePath: { regex: "/main/adserver.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        offerwall: file(absolutePath: { regex: "/main/offerwall.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  const publishers: PublisherType[] = useMemo(
    () => [
      {
        key: 'monetize',
        title: t('home.publisher.monetize.title'),
        description: t('home.publisher.monetize.description'),
        image: monetize.childImageSharp.gatsbyImageData,
      },
      {
        key: 'adserver',
        title: t('home.publisher.adserver.title'),
        description: t('home.publisher.adserver.description'),
        image: adserver.childImageSharp.gatsbyImageData,
      },
      {
        key: 'offerwall',
        title: t('home.publisher.rewardMonetize.title'),
        description: t('home.publisher.rewardMonetize.description'),
        image: offerwall.childImageSharp.gatsbyImageData,
      },
    ],
    [t],
  );

  const selectedImage = useMemo(
    () => publishers.find(({ key }) => key === select)?.image,
    [select],
  );

  return (
    <section
      className={cn(
        'flex flex-col bg-[#FFFFFF] justify-center items-center',
        'lg:py-[100px] lg:px-0',
        'py-[50px] px-[20px]',
      )}
    >
      <div className="flex flex-col max-w-[695px]">
        <h6
          className={cn(
            'text-center text-[#2685F4] text-[16px] leading-normal font-semibold',
            'lg:text-[14px] leading-[21px] lg:mb-[4px]',
            'text-[16px] leading-[24px] mb-[2px]',
          )}
        >
          Publisher
        </h6>
        <h3
          className={cn(
            'text-[#212529] text-center font-bold leading-[134%] tracking-[-0.3px] mb-[10px]',
            'lg:text-[36px]',
            'text-[24px]',
          )}
        >
          {t('home.publisher.title')}
        </h3>
        <p
          className={cn(
            'text-center text-[#808C99] font-normal tracking-[-0.3px] leading-[160%] mb-[30px]',
            'lg:text-[18px]',
            'text-[16px]',
          )}
        >
          {t('home.publisher.description')}
        </p>
      </div>
      <div
        className={cn(
          'flex justify-center items-center w-full max-w-[1200px]',
          'lg:mt-[30px] lg:flex-row lg:gap-0 lg:justify-between',
          'flex-col gap-[40px]',
        )}
      >
        <div className={cn('flex flex-col', 'lg:max-w-[500px]')}>
          {publishers.map(({ key, title, description }) => (
            <button
              key={key}
              className={cn(
                'flex',
                "before:content-[''] before:w-[3px] before:h-[137px]  before:rounded-[100px]",
                {
                  'before:bg-[#2685F4]': select === key,
                  'before:bg-[#F1F2F4]': select !== key,
                },
              )}
              onClick={onClickItem(key)}
            >
              <div className="py-[20px] pl-[30px]">
                <h4
                  className={cn('flex items-center text-[26px] font-bold mb-[6px]', {
                    'text-[#212529]': select === key,
                    'text-[#B8BFC6]': select !== key,
                  })}
                >
                  <span className="mr-[10px]">{title}</span>
                  <IconArrowRight />
                </h4>
                <p
                  className={cn(
                    'text-left font-normal tracking-[-0.3px] leading-[160%]',
                    'lg:text-[16px]',
                    'text-[14px]',
                    {
                      'text-[#808C99]': select === key,
                      'text-[#B8BFC6]': select !== key,
                    },
                  )}
                >
                  {description}
                </p>
              </div>
            </button>
          ))}
          <div className="absolute w-[3px] h-[411px] bg-[#F1F2F4] rounded-[100px] z-[-1]" />
        </div>
        <div>
          {selectedImage && (
            <GatsbyImage
              objectFit="contain"
              className={cn({
                'lg:w-[600px] lg:h-[500px] w-[320px] h-[298px]': select === 'monetize',
                'lg:w-[600px] lg:h-[500px] w-[320px] h-[320px]': select !== 'monetize',
              })}
              image={selectedImage}
              alt="publisher.png"
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Publisher;
