import bunjang from 'assets/main/bunjang.png';
import hanaMembers from 'assets/main/hana-members.png';
import kakaopage from 'assets/main/kakaopage.png';
import lotteCinema from 'assets/main/lotte-cinema.png';
import lPoint from 'assets/main/lpoint.png';
import naverWebtoon from 'assets/main/naver-webtoon.png';
import okCashbag from 'assets/main/ok-cashbag.png';
import oneStore from 'assets/main/one-store.png';
import oneStory from 'assets/main/one-story.png';
import sevenEleven from 'assets/main/seven-eleven.png';
import spoon from 'assets/main/spoon.png';
import whowho from 'assets/main/whowho.png';
import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Partner = () => {
  const { t } = useTranslation();

  const images = useMemo(
    () => [
      whowho,
      oneStore,
      sevenEleven,
      spoon,
      bunjang,
      hanaMembers,
      oneStory,
      lPoint,
      lotteCinema,
      okCashbag,
      naverWebtoon,
      kakaopage,
    ],
    [],
  );
  return (
    <section
      className={cn(
        'flex flex-col bg-[#FBFCFD] justify-center items-center',
        'lg:py-[100px] lg:px-0',
        'py-[50px] px-[20px]',
      )}
    >
      <span
        className={cn(
          'font-normal text-[18px] tracking-[-0.3px] text-[#808C99]',
          'lg:text-[18px] lg:leading-[21px] lg:mb-[60px]',
          'text-[16px] leading-[160%] mb-[30px]',
        )}
      >
        {t('home.partner.description')}
      </span>
      <div
        className={cn(
          'grid items-center justify-center justify-items-center',
          'lg:max-w-[1200px] lg:pb-0 lg:grid-cols-6 lg:gap-x-[60px] lg:gap-y-[10px]',
          'w-full pb-[30px] grid-cols-3 gap-x-[25px] gap-y-[10px]',
        )}
      >
        {images.map((img, idx) => (
          <img
            key={idx}
            src={img}
            alt="partner.png"
            className={cn('object-contain', 'lg:w-[150px] lg:h-[86px]', 'w-[90px] h-[66px]')}
          />
        ))}
      </div>
    </section>
  );
};

export default Partner;
