import cn from 'classnames';
import { Intro } from 'components/common';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const MainIntro = () => {
  const { t } = useTranslation();
  const { backgroundImage } = useStaticQuery<{
    backgroundImage: ImageDataLike;
  }>(
    graphql`
      query IndexPageQuery {
        backgroundImage: file(absolutePath: { regex: "/main/intro.png/" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `,
  );

  return (
    <Intro backgroundImage={backgroundImage}>
      <h1
        className={cn(
          'text-white font-bold',
          'lg:text-[60px] lg:leading-[124%] lg:mb-[8px]',
          'text-[40px] leading-[110%] mb-[10px] px-[20px]',
        )}
      >
        {t('home.intro.title')}
      </h1>
      <p
        className={cn(
          'text-white tracking-[-0.3px] font-normal',
          'lg:text-[18px] lg:leading-[160%] lg:mb-[30px] lg:whitespace-pre-line',
          'text-[16px] leading-[160%] mb-[20px] px-[20px]',
        )}
      >
        {t('home.intro.description')}
      </p>
      <div>
        <Link
          className="flex items-center justify-center w-full text-white bg-[#2685F4] py-[12px] px-[20px] rounded-[6px] hover:bg-[#2685F4]/80 focus:outline-none"
          to="/contact#advertisers"
        >
          {t('contact')}
        </Link>
      </div>
    </Intro>
  );
};

export default MainIntro;
